import React, { useEffect, useState } from 'react';
import './Main.scss';
import TopComponent from './components/TopComponent/TopComponent';
import WhatWeProvide from './components/WhatWeProvide/WhatWeProvide';
import Destinations from './components/Destinations/Destinations';
import Benefits from './components/Benefits/Benefits';
import PopularDecisions from './components/PopularDecisions/PopularDecisions';
import OurBlog from './components/OurBlog/OurBlog';
import { getCountries } from '../../api/Countries/Countries.api';
import { getBenefits } from '../../api/Benefits/Benefits.api';
import { getPopularDecisions } from '../../api/Tours/Tours.api';
import { getBlogs } from '../../api/Blogs/Blogs.api';

const Main = () => {
  const [destinations, setDestinations] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [popularDecisions, setPopularDecisions] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getDestinations() {
    const response = await getCountries();
    setDestinations(response.data.results);
  }

  async function getAllBenefits() {
    const response = await getBenefits();
    setBenefits(response.data.results);
  }

  async function GetPopularDecisions() {
    const response = await getPopularDecisions();
    setPopularDecisions(response?.results);
  }

  async function getAllBlogs() {
    const response = await getBlogs();
    setBlogs(response?.data?.results);
  }

  const fetchData = async () => {
    await Promise.all([
      getDestinations(),
      getAllBenefits(),
      GetPopularDecisions(),
      getAllBlogs(),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="Main">
      <TopComponent />
      {/* <SearchBar /> */}
      <WhatWeProvide />
      <Destinations destinations={destinations} />
      <Benefits benefits={benefits} />
      <PopularDecisions popularDecisions={popularDecisions} />
      <OurBlog blogs={blogs} />
    </div>
  );
};

export default Main;
