// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsCard {
  max-width: 282px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  gap: 16px;
  -webkit-user-select: none;
          user-select: none;
}
.NewsCard img {
  width: 100%;
  height: 200px;
}
.NewsCard__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}
.NewsCard__info-date {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/NewsCard/NewsCard.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;UAAA,iBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,eAAA;AAAJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACN","sourcesContent":[".NewsCard {\n  max-width: 282px;\n  width: 100%;\n  border-radius: 16px;\n  overflow: hidden;\n  display: flex;\n  padding-bottom: 16px;\n  flex-direction: column;\n  gap: 16px;\n  user-select: none;\n\n  img {\n    width: 100%;\n    height: 200px;\n  }\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    padding: 0 16px;\n    &-date {\n      display: flex;\n      align-items: center;\n      gap: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
