import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDown2 } from 'iconsax-react';
import './InputDrop.scss';
import { Headline5 } from '../Text/Headlines/Headlines';
import { CaptionsText } from '../Text/Body/Bodies';

// Используем styled для создания компонента с динамическими стилями
const StyledTextField = styled(TextField)(({ backgroundColor }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: backgroundColor || '#F5F6F7', // Используем цвет из пропсов или значение по умолчанию
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: backgroundColor || '#F5F6F7',
    '&:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& input::placeholder': {
      color: '#4E5666',
    },
    '&.Mui-focused': {
      borderColor: '#007C6E',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: backgroundColor || '#F5F6F7',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#4E5666',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#4E5666',
  },
  '& .MuiFilledInput-underline:before': {
    borderBottom: 'none',
    display: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: 'none',
    display: 'none',
  },
}));

const InputDrop = ({
  field,
  form,
  inputLabel,
  label,
  placeholder,
  options,
  description,
  className,
  backgroundColor, // Добавляем backgroundColor в пропсы
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <div className="InputDrop">
      {label && <Headline5 variant={'dark900'}>{label}</Headline5>}
      <Autocomplete
        value={options.find(option => option.value === value) || null}
        options={options}
        className={className}
        getOptionLabel={option => option.label}
        popupIcon={<ArrowDown2 />}
        isOptionEqualToValue={(option, value) => option.value === value}
        onChange={(event, newValue) => {
          setFieldValue(name, newValue ? newValue.value : '');
        }}
        renderInput={params => (
          <StyledTextField
            {...params}
            label={inputLabel}
            hiddenLabel={inputLabel ? false : true}
            fullWidth
            variant={'filled'}
            placeholder={placeholder || 'Placeholder'}
            backgroundColor={backgroundColor} // Передаем backgroundColor в стили
          />
        )}
        {...props}
      />
      {description && (
        <CaptionsText className="InputDrop__description">
          {description}
        </CaptionsText>
      )}
    </div>
  );
};

export default InputDrop;
