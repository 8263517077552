import React from 'react';
import { makeStyles } from '@mui/styles';
import { Calendar } from 'iconsax-react';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePickerField from '../../../../components/DateRangePicker/DateRangePickerField';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F5F6F7',
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F5F6F7',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& input::placeholder': {
      color: '#4E5666',
    },
    '&.Mui-focused': {
      borderColor: '#007C6E',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: '#F5F6F7',
    },
  },
  label: {
    color: '#4E5666',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    '&.Mui-focused': {
      color: '#4E5666',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '&:after': {
      borderBottom: 'none',
      display: 'none',
    },
  },
}));

const DateField = ({
  field,
  label,
  form,
  inputType,
  selectRange = false,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = newDate => {
    form.setFieldValue(field.name, newDate);
  };

  return (
    <div className="DateField">
      <p className={classes.label}>{label}</p>
      <div className={classes.root}>
        <DateRangePickerField
          selectRange={selectRange}
          onChange={handleChange}
          value={field.value}
          {...props}
        />
        <Calendar color="#9b9d9d" className="DateField__icon" />
      </div>
    </div>
  );
};

export default DateField;
