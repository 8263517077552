import React from 'react';

import './Navbar.scss';
import { Link, NavLink } from 'react-router-dom';
import OutlinedButton from '../../../../components/Buttons/OutlinedButton/OutlinedButton';
import GhostButton from '../../../../components/Buttons/GhostButton/GhostButton';
import { useSetRecoilState } from 'recoil';
import { loginModal } from '../../../../store/modals/modals.store';
import NavbarItem from './NavbarItem';

const Navbar = () => {
  const setLoginModal = useSetRecoilState(loginModal);
  return (
    <nav className="Navbar">
      <div className="container">
        <div className="Navbar__links">
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/'}
          >
            Home
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/destinations'}
          >
            Destinations
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/popular-decisions'}
          >
            Popular decision
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/news'}
          >
            Blog
          </NavbarItem>
          <NavbarItem
            className={({ isActive }) =>
              isActive ? 'Navbar__links-item active' : 'Navbar__links-item'
            }
            to={'/about-us'}
          >
            About us
          </NavbarItem>
        </div>

        <div className="Navbar__buttons">
          <GhostButton text={'Log in'} action={() => setLoginModal(true)} />
          <OutlinedButton text={'Find tour'} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
