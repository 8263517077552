import React from 'react';

import './WhatSayAboutUsCard.scss';
import { Headline4 } from '../../../../components/Text/Headlines/Headlines';

import CalendarIcon from '../../../../assets/icons/calendar.svg';
import RatingIcon from '../../../../assets/icons/magic-star.svg';

const WhatSayAboutUsCard = ({ date, comment, customer, rating }) => {
  return (
    <div className="WhatSayAboutUsCard">
      <p className="WhatSayAboutUsCard__date">
        <img src={CalendarIcon} alt="" />
        {date}
      </p>

      <div className="WhatSayAboutUsCard__content">
        <p className="WhatSayAboutUsCard__content__comment">{comment}</p>

        <Headline4>{customer}</Headline4>

        <p className="WhatSayAboutUsCard__content__rating">
          <img src={RatingIcon} alt="" />
          {rating}
        </p>
      </div>
    </div>
  );
};

export default WhatSayAboutUsCard;
