import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Headline2,
  Headline3,
} from '../../components/Text/Headlines/Headlines';
import InputField from './components/InputField/InputField';
import InputDrop from '../../components/InputDrop/InputDrop';
import './TourForm.scss';
import SwitchField from './components/CheckboxField/SwitchField';
import { InfoCircle } from 'iconsax-react';
import { ButtonLText, DetailsText } from '../../components/Text/Body/Bodies';
import { ButtonL } from '../../components/Buttons/Buttons';
import DateField from './components/DateField/DateField';
import { createReservation } from '../../api/Reservation/Reservation.api';

const TourForm = () => {
  const options = [
    {
      label: 'Between 08:00 AM & 11:00 AM',
      value: 'Between 08:00 AM & 11:00 AM',
    },
    {
      label: 'Between 11:00 AM & 02:00 PM',
      value: 'Between 11:00 AM & 02:00 PM',
    },
    {
      label: 'Between 02:00 PM & 06:00 PM',
      value: 'Between 02:00 PM & 06:00 PM',
    },
  ];

  const handleSubmit = values => {
    createReservation(values);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
  });

  return (
    <div className="TourForm">
      <div className="container">
        <div className="TourForm__wrapper">
          <Headline2 variant={'dark900'} textAlign={'center'}>
            Dolce Tempo Journey from Montreux to Lucerne <br /> via the Swiss
            Alps
          </Headline2>
          <div className="TourForm__dates">
            <div className="TourForm__dates-date">
              <p className="TourForm__dates-date__day">Mon</p>
              <span className="TourForm__dates-date__span">Apr 29</span>
            </div>
            <div className="TourForm__dates-duration">
              <div className="TourForm__dates-duration__days">9 days</div>
              <div className="TourForm__dates-duration__line">
                <div className="TourForm__dates-duration__line-dot"></div>
                <div className="TourForm__dates-duration__line-dashed"></div>
                <div className="TourForm__dates-duration__line-arrow"></div>
              </div>
              <div className="TourForm__dates-duration__type">Watch</div>
            </div>
            <div className="TourForm__dates-date">
              <p className="TourForm__dates-date__day">Tue</p>
              <span className="TourForm__dates-date__span">May 07</span>
            </div>
          </div>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phoneNumber: '',
              BestTimeToCallYou: null,
              email: '',
              address: '',
              city: '',
              state: null,
              zip: '',
              country: null,
              birthDate: new Date(),
              riderType: null,
              tripOccasion: null,
              height: null,
              bike: null,
              clothingSize: null,
              occupation: null,
              jerseySize: null,
              message: '',
              travel_partner: '',
              is_trip_protection: false,
              is_newsletter: false,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ isValid, dirty }) => (
              <Form className="TourForm__form">
                <div className="TourForm__form-block">
                  <div className="TourForm__form-block__title">
                    <Headline3 variant={'dark900'}>
                      Contact information
                    </Headline3>
                    <Headline3 variant={'jungle500'}>01</Headline3>
                  </div>
                  <div className="TourForm__form-block__inputs">
                    <Field
                      name="firstName"
                      component={InputField}
                      inputLabel="First name"
                      placeholder="Enter first name"
                    />
                    <Field
                      name="lastName"
                      component={InputField}
                      inputLabel="Last name"
                      placeholder="Enter last name"
                    />
                    <Field
                      name="phoneNumber"
                      component={InputField}
                      inputLabel="Phone number"
                      inputType="phone"
                      placeholder="Enter your phone number"
                    />
                    <Field
                      name="BestTimeToCallYou"
                      component={InputDrop}
                      inputLabel="Best time to call you"
                      options={options}
                      placeholder="Best time to call you"
                    />
                    <Field
                      name="email"
                      component={InputField}
                      inputLabel="Email"
                      inputType="email"
                      placeholder="Enter your email"
                    />
                    <Field
                      name="address"
                      component={InputField}
                      inputLabel="Adress"
                      placeholder="Enter your adress"
                    />
                    <Field
                      name="city"
                      component={InputField}
                      inputLabel="City"
                      placeholder="Enter your city"
                    />
                    <Field
                      name="state"
                      component={InputDrop}
                      inputLabel="State"
                      options={options}
                      placeholder="Select your state"
                    />
                    <Field
                      name="zip"
                      component={InputField}
                      inputLabel="Zip/Postal code"
                      placeholder="Enter your Zip/Postal code"
                    />
                    <Field
                      name="country"
                      component={InputDrop}
                      inputLabel="Country"
                      options={options}
                      placeholder="Select your country"
                    />
                    <Field
                      name="birthDate"
                      component={DateField}
                      inputLabel="Date of birth"
                      placeholder="Select your Date of birth"
                      selectRange={false}
                      locale={'en-EN'}
                    />
                    <Field
                      name="travel_partner"
                      component={InputField}
                      inputLabel="Name of travel partner"
                      placeholder="Enter your travel partner"
                    />
                  </div>
                </div>
                <div className="TourForm__form-block">
                  <div className="TourForm__form-block__title">
                    <Headline3 variant={'dark900'}>
                      Your trip information
                    </Headline3>
                    <Headline3 variant={'jungle500'}>02</Headline3>
                  </div>
                  <div className="TourForm__form-block__inputs">
                    <Field
                      name="riderType"
                      component={InputDrop}
                      inputLabel="Rider type"
                      options={options}
                      placeholder="Select rider type"
                    />
                    <Field
                      name="tripOccasion"
                      component={InputDrop}
                      inputLabel="Trip occasion"
                      options={options}
                      placeholder="Select Trip occasion"
                    />
                    <Field
                      name="height"
                      component={InputDrop}
                      inputLabel="Your height"
                      options={options}
                      placeholder="Select Your height"
                    />
                    <Field
                      name="bike"
                      component={InputDrop}
                      inputLabel="Bike selection"
                      options={options}
                      placeholder="Select Bike selection"
                    />
                    <Field
                      name="clothingSize"
                      component={InputDrop}
                      inputLabel="Clothing size"
                      options={options}
                      description={
                        'Excludes Ride Camp, Self-Guided, or Discover trips'
                      }
                      placeholder="Select Clothing size"
                    />
                    <Field
                      name="occupation"
                      component={InputDrop}
                      inputLabel="What is your Occupation"
                      options={options}
                      placeholder="Select occupation"
                    />
                    <Field
                      name="jerseySize"
                      component={InputDrop}
                      inputLabel="Your jersey size"
                      options={options}
                      description={
                        'Trip Consultant who helped you (if applicable)'
                      }
                      placeholder="Select jersey size"
                    />
                    <Field
                      name="message"
                      component={InputField}
                      height={'104px'}
                      inputLabel="Message to Trip Consultant"
                      placeholder="Message to Trip Consultant"
                    />
                    <Field
                      name="is_trip_protection"
                      component={SwitchField}
                      label="Interested in Trip Protection*"
                    />
                    <Field
                      name="is_newsletter"
                      component={SwitchField}
                      label="Get the Trek Travel eNewsletter"
                    />
                  </div>
                </div>
                <div className="TourForm__form-warning">
                  <InfoCircle color="#3D67FF" variant="Bold" />
                  <DetailsText variant={'dark720'}>
                    Please wait for the confirmation message after clicking
                    submit below to ensure we received your information.
                  </DetailsText>
                </div>
                <ButtonL
                  variant={'primary'}
                  fullWidth={true}
                  action="submit"
                  disabled={!(isValid && dirty)}
                >
                  <ButtonLText variant={'white'}>Submit</ButtonLText>
                </ButtonL>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TourForm;
