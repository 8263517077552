import {
  Headline2,
  Headline4,
} from '../../components/Text/Headlines/Headlines';
import './DestinationsPage.scss';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CountryCard from './components/CountryCards/CountryCard';
import { getCountries } from '../../api/Countries/Countries.api';
import { ImportantText } from '../../components/Text/Body/Bodies';

const DestinationsPage = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getCountriesDestinations() {
    const response = await getCountries();

    setCountries(response.data.results);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    getCountriesDestinations();
  }, []);

  return (
    <div className="DestinationsPage">
      <div className="container">
        <Headline4 variant={'jungle500'}>Explore our Destinations</Headline4>
        <div className="DestinationsPage__title">
          <Headline2 variant={'dark900'}>Featured Countries</Headline2>
          <ImportantText
            className={'DestinationsPage__title-text'}
            variant={'dark720'}
          >
            Take the first step towards your next life-affirming adventure.
          </ImportantText>
        </div>
        <div className="DestinationsPage__cards">
          {loading ? (
            <>
              <Skeleton variant="rounded" width={282} height={331}></Skeleton>
              <Skeleton variant="rounded" width={282} height={331}></Skeleton>
              <Skeleton variant="rounded" width={282} height={331}></Skeleton>
              <Skeleton variant="rounded" width={282} height={331}></Skeleton>
            </>
          ) : (
            countries.map(item => (
              <CountryCard to={`/destinations/${item.id}`} image={item.icon}>
                {item.name}
              </CountryCard>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default DestinationsPage;
