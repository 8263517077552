import { backend } from './../index';

const endpoint = '/auth/signin/';

export const signIn = async data => {
  try {
    const response = await backend.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};
