// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DestinationsCard {
  border-radius: 12px;
  display: flex;
  width: 282px;
  height: 352px;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px;
}
.DestinationsCard__title {
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
}
.DestinationsCard__priceBlock {
  display: flex;
  background: #fff;
  width: 100%;
  border-radius: 12px;
  padding: 8px 16px;
  justify-content: space-between;
}
.DestinationsCard__priceBlock-left__from {
  font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #747b8a;
}
.DestinationsCard__priceBlock-left__price {
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #000;
}
.DestinationsCard__priceBlock-left__price span {
  color: #747b8a;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/Destinations/components/DestinationsCard/DestinationsCard.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,4BAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,WAAA;AAAJ;AAEE;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,8BAAA;AAAJ;AAGM;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADR;AAIM;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAFR;AAGQ;EACE,cAAA;AADV","sourcesContent":[".DestinationsCard {\n  border-radius: 12px;\n  display: flex;\n  width: 282px;\n  height: 352px;\n  flex-direction: column;\n  justify-content: flex-end;\n  background-repeat: no-repeat;\n  background-size: cover;\n  padding: 5px;\n\n  &__title {\n    font-family: Mulish, sans-serif;\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 32px;\n    text-align: left;\n    margin-bottom: 10px;\n\n    color: #fff;\n  }\n  &__priceBlock {\n    display: flex;\n    background: #fff;\n    width: 100%;\n    border-radius: 12px;\n    padding: 8px 16px;\n    justify-content: space-between;\n\n    &-left {\n      &__from {\n        font-family: Mulish, sans-serif;\n        font-size: 12px;\n        font-weight: 400;\n        line-height: 16px;\n        text-align: left;\n        color: #747b8a;\n      }\n\n      &__price {\n        font-family: Mulish, sans-serif;\n        font-size: 20px;\n        font-weight: 700;\n        line-height: 28px;\n        text-align: center;\n        color: #000;\n        span {\n          color: #747b8a;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
