import React from 'react';

const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M0.5 8.05C0.5 5.20255 0.5 3.77882 1.06293 2.69513C1.53731 1.78191 2.28191 1.03731 3.19513 0.562933C4.27882 0 5.70255 0 8.55 0H16.45C19.2975 0 20.7212 0 21.8049 0.562933C22.7181 1.03731 23.4627 1.78191 23.9371 2.69513C24.5 3.77882 24.5 5.20255 24.5 8.05V15.95C24.5 18.7975 24.5 20.2212 23.9371 21.3049C23.4627 22.2181 22.7181 22.9627 21.8049 23.4371C20.7212 24 19.2975 24 16.45 24H8.55C5.70255 24 4.27882 24 3.19513 23.4371C2.28191 22.9627 1.53731 22.2181 1.06293 21.3049C0.5 20.2212 0.5 18.7975 0.5 15.95V8.05Z"
        fill="white"
      />
      <path
        d="M8.55 0.25H16.45C17.8779 0.25 18.9336 0.250201 19.7682 0.319509C20.5997 0.388569 21.1894 0.524946 21.6896 0.784786C22.5572 1.23544 23.2646 1.94282 23.7152 2.81037C23.9751 3.31059 24.1114 3.90026 24.1805 4.73184C24.2498 5.56642 24.25 6.62208 24.25 8.05V15.95C24.25 17.3779 24.2498 18.4336 24.1805 19.2682C24.1114 20.0997 23.9751 20.6894 23.7152 21.1896C23.2646 22.0572 22.5572 22.7646 21.6896 23.2152C21.1894 23.4751 20.5997 23.6114 19.7682 23.6805C18.9336 23.7498 17.8779 23.75 16.45 23.75H8.55C7.12208 23.75 6.06642 23.7498 5.23184 23.6805C4.40026 23.6114 3.81059 23.4751 3.31037 23.2152C2.44282 22.7646 1.73544 22.0572 1.28479 21.1896C1.02495 20.6894 0.888569 20.0997 0.819509 19.2682C0.750201 18.4336 0.75 17.3779 0.75 15.95V8.05C0.75 6.62208 0.750201 5.56642 0.819509 4.73184C0.888569 3.90026 1.02495 3.31059 1.28479 2.81037C1.73544 1.94282 2.44282 1.23544 3.31037 0.784786C3.81059 0.524946 4.40026 0.388569 5.23184 0.319509C6.06642 0.250201 7.12208 0.25 8.55 0.25Z"
        stroke="black"
        stroke-opacity="0.15"
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.66 12.1932C20.66 11.5905 20.6059 11.011 20.5055 10.4546H12.5V13.7425H17.0746C16.8775 14.8051 16.2786 15.7053 15.3784 16.308V18.4407H18.1255C19.7327 16.961 20.66 14.7819 20.66 12.1932Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5002 20.5C14.7952 20.5 16.7193 19.7389 18.1257 18.4407L15.3786 16.3079C14.6175 16.8179 13.6439 17.1193 12.5002 17.1193C10.2864 17.1193 8.41248 15.6241 7.74407 13.615H4.9043V15.8173C6.30294 18.5952 9.17749 20.5 12.5002 20.5Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74387 13.6151C7.57387 13.1051 7.47728 12.5604 7.47728 12.0001C7.47728 11.4399 7.57387 10.8951 7.74387 10.3851V8.18286H4.90409C4.32841 9.33036 4 10.6285 4 12.0001C4 13.3717 4.32841 14.6699 4.90409 15.8174L7.74387 13.6151Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5002 6.88069C13.7482 6.88069 14.8686 7.30955 15.7495 8.15183L18.1875 5.71387C16.7155 4.34227 14.7914 3.5 12.5002 3.5C9.17748 3.5 6.30294 5.40478 4.9043 8.18274L7.74407 10.385C8.41248 8.37592 10.2864 6.88069 12.5002 6.88069Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
