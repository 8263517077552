import React, { useEffect, useState } from 'react';

import './NewsPage.scss';
import { Headline2 } from '../../components/Text/Headlines/Headlines';
import OurBlogCard from '../Main/components/OurBlog/components/OurBlogCard/OurBlogCard';

import PopularDecisionCardImg from '../../assets/images/Main/PopularDecisions/CaminoDeSantiago.png';
import { getBlogs } from '../../api/Blogs/Blogs.api';
import { Skeleton } from '@mui/material';

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getNews() {
    const response = await getBlogs();
    setNews(response.data.results);
    setLoading(false);
  }

  useEffect(() => {
    getNews();
  }, []);

  return (
    <div className="NewsPage">
      <div className="container">
        <div className="NewsPage__content">
          <Headline2>News Blog</Headline2>

          <div className="NewsPage__cards">
            {loading ? (
              <>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
                <Skeleton variant="rounded" width={282} height={422}></Skeleton>
              </>
            ) : (
              news?.map(item => (
                <OurBlogCard
                  loading={loading}
                  image={item.icon}
                  title={item.title}
                  description={item.text}
                  date={item.created_at}
                  to={'/news/details'}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
