import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from '@mui/material';
import {
  ButtonLText,
  ButtonMText,
  CaptionsText,
  DetailsText,
  MainText,
} from '../../components/Text/Body/Bodies';
import {
  Headline2,
  Headline3,
  Headline4,
} from '../../components/Text/Headlines/Headlines';
import {
  getAllSchedules,
  getUpcomingTours,
} from '../../api/SchedulerOfTours/SchedulerOfTours.api';
import 'swiper/css';
import './TourDetail.scss';
import { Field, Formik } from 'formik';
import { Navigation } from 'swiper/modules';
import { Element, Link } from 'react-scroll';
import Map from '../../assets/images/map.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import BikeCard from './components/BikeCard/BikeCard';
import { getTourById } from '../../api/Tours/Tours.api';
import { useNavigate, useParams } from 'react-router-dom';
import HotelsCard from './components/HotelsCard/HotelsCard';
import InputDrop from '../../components/InputDrop/InputDrop';
import { getProducts } from '../../api/Products/Products.api';
import { ButtonL, ButtonM } from '../../components/Buttons/Buttons';
import { ArrowDown2, ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import ScheduledPriceCard from './components/ScheduledPriceCard/ScheduledPriceCard';

const TourDetail = () => {
  const navigate = useNavigate();
  const { tourName } = useParams();
  const [tour, setTour] = useState({});
  const [bikes, setBikes] = useState([]);
  const [dayToDay, setDayToDay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schedulers, setSchedulers] = useState([]);
  const [activeTab, setActiveTab] = useState('bikes');
  const [bikesLoading, setBikesLoading] = useState(true);

  const images = tour?.tour_images
    ?.filter(image => !image.is_main)
    ?.slice(0, 4); // Обрезаем до 4 картинок
  const imagesCount = images?.length;

  async function getTours() {
    const response = await getTourById(tourName);
    setTour(response);
  }
  async function getDayToDay() {
    const response = await getUpcomingTours({ tour__id: tourName });

    setDayToDay(response.results);
  }
  async function getScheduler() {
    const response = await getAllSchedules({ tour__id: tourName });

    setSchedulers(response.results);
  }
  async function getProductsByTourType() {
    setBikesLoading(true);

    const response = await getProducts({ tour_types: tour?.tour_type?.id });

    setBikes(response?.data?.results);
    setBikesLoading(false);
  }
  async function fetchData() {
    await Promise.all([getTours(), getScheduler(), getDayToDay()]);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 'bikes':
        getProductsByTourType();

      default:
        setBikesLoading(true);
    }
  }, [activeTab]);

  return (
    <div className="TourDetail">
      <Element className="TourDetail__banner container" name="Banner">
        {loading ? (
          <Skeleton
            variant="text"
            width={'40%'}
            sx={{ fontSize: '32px', lineHeight: '44px' }}
          ></Skeleton>
        ) : (
          <Headline2 variant={'dark900'}>{tour.title}</Headline2>
        )}
        <div className="TourDetail__banner-photos">
          {loading ? (
            <Skeleton variant="rounded" width={588} height={420}></Skeleton>
          ) : (
            <img
              className="main-image"
              alt="MainImage"
              src={tour?.tour_images.find(image => image.is_main)?.url}
            />
          )}
          {loading ? (
            <div className="TourDetail__banner-photos__small skeleton">
              <Skeleton variant="rounded" width={282} height={198}></Skeleton>
              <Skeleton variant="rounded" width={282} height={198}></Skeleton>
              <Skeleton variant="rounded" width={282} height={198}></Skeleton>
              <Skeleton variant="rounded" width={282} height={198}></Skeleton>
            </div>
          ) : (
            <div
              className={`TourDetail__banner-photos__small images-count-${imagesCount}`}
            >
              {images.map(image => (
                <img src={image?.url} alt="" key={image?.url} />
              ))}
            </div>
          )}
        </div>
      </Element>

      <Element className="TourDetail__nav" name="sticky-navigation">
        <nav className="TourDetail__nav-links container">
          <nav className="TourDetail__nav-links container">
            <ul className="TourDetail__nav-links__list">
              <li className="TourDetail__nav-links__list-item">
                <MainText variant={'dark900'} textAlign={'center'}>
                  <Link
                    to="Overview"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="is-active"
                  >
                    Overview
                    <div className="line" />
                  </Link>
                </MainText>
              </li>
              <li className="TourDetail__nav-links__list-item">
                <MainText variant={'dark900'}>
                  <Link
                    to="What we included"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="is-active"
                  >
                    What we included
                    <div className="line" />
                  </Link>
                </MainText>
              </li>
              <li className="TourDetail__nav-links__list-item">
                <MainText variant={'dark900'}>
                  <Link
                    to="Date & Prices"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="is-active"
                  >
                    Dates&Prices
                    <div className="line" />
                  </Link>
                </MainText>
              </li>
              <li className="TourDetail__nav-links__list-item">
                <MainText variant={'dark900'}>
                  <Link
                    to="Day to day"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="is-active"
                  >
                    Day to day
                    <div className="line" />
                  </Link>
                </MainText>
              </li>
              <li className="TourDetail__nav-links__list-item">
                <MainText variant={'dark900'}>
                  <Link
                    to="Reviews"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="is-active"
                  >
                    Reviews
                    <div className="line" />
                  </Link>
                </MainText>
              </li>
            </ul>
          </nav>
        </nav>
      </Element>

      <Element className="TourDetail__about container" name="Overview">
        <div className="TourDetail__about__text">
          <h4 className="TourDetail__about__text__title">About tour</h4>
          {loading ? (
            <Skeleton variant="rounded" width={'100%'} height={245}></Skeleton>
          ) : (
            <p className="TourDetail__about__text__description">
              {tour.description}
            </p>
          )}
        </div>

        <div className="TourDetail__about__info">
          <div className="TourDetail__about__info__title">
            <DetailsText variant={'dark720'} textAlign={'center'}>
              From:
            </DetailsText>
            <Headline3 variant={'dark900'}>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={'70px'}
                  height={30}
                  sx={{ fontSize: '24px', lineHeight: '32px' }}
                ></Skeleton>
              ) : (
                '$9999'
              )}
              <DetailsText variant={'dark720'}>/ person</DetailsText>
            </Headline3>
          </div>

          <div className="TourDetail__about__info__details">
            <div className="TourDetail__about__info__details__item">
              <p className="TourDetail__about__info__details__item__title">
                <CaptionsText variant={'dark720'}>Duration days</CaptionsText>
                <DetailsText variant={'dark900'} textAlign={'center'}>
                  {loading ? <Skeleton /> : `${tour.duration_day} days`}
                </DetailsText>
              </p>
            </div>
            <div className="TourDetail__about__info__details__item">
              <p className="TourDetail__about__info__details__item__title">
                <CaptionsText variant={'dark720'}>Duration nights</CaptionsText>
                <DetailsText variant={'dark900'} textAlign={'center'}>
                  {loading ? <Skeleton /> : `${tour.duration_night} nights`}
                </DetailsText>
              </p>
            </div>
            <div className="TourDetail__about__info__details__item">
              <p className="TourDetail__about__info__details__item__title">
                <CaptionsText variant={'dark720'}>Trip level</CaptionsText>
                <DetailsText variant={'dark900'} textAlign={'center'}>
                  {loading ? <Skeleton /> : tour?.level?.title}
                </DetailsText>
              </p>
            </div>
            <div className="TourDetail__about__info__details__item">
              <p className="TourDetail__about__info__details__item__title">
                <CaptionsText variant={'dark720'}>Hotel level</CaptionsText>
                <DetailsText variant={'dark900'} textAlign={'center'}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    tour?.hotels[0]?.title || 'Undefined'
                  )}
                </DetailsText>
              </p>
            </div>
          </div>

          <div className="TourDetail__about__info__btn">
            <ButtonL
              fullWidth
              action={'button'}
              disabled={loading}
              onClick={() => navigate('/tour-form')}
            >
              <ButtonLText variant={'white'}>
                <Link
                  to="dates"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-200}
                  activeClass="is-active"
                >
                  Book tour
                  <div className="line" />
                </Link>
              </ButtonLText>
            </ButtonL>
          </div>

          {/* <button
              className="TourDetail__about__info__btn"
              onClick={() => navigate('/tour-form')}
            >
              Book tour
            </button> */}
        </div>
      </Element>

      <Element className="TourDetail__included" name="What we included">
        <Headline2 variant={'dark900'} className={'container'}>
          What we included
        </Headline2>

        <Element className="TourDetail__included-nav" name="What Includes Tabs">
          <ul className="TourDetail__included-nav__tabs container">
            <li
              className={`TourDetail__included-nav__tabs-tab ${activeTab === 'bikes' ? 'active' : ''}`}
              value={'bikes'}
              onClick={e => setActiveTab(e.target.getAttribute('value'))}
            >
              <MainText variant={'dark900'}>Bikes</MainText>
              <div className="line" />
            </li>
            <li
              className={`TourDetail__included-nav__tabs-tab ${activeTab === 'included' ? 'active' : ''}`}
              value={'included'}
              onClick={e => setActiveTab(e.target.getAttribute('value'))}
            >
              <MainText variant={'dark900'}>What included</MainText>
              <div className="line" />
            </li>
            <li
              className={`TourDetail__included-nav__tabs-tab ${activeTab === 'gear' ? 'active' : ''}`}
              value={'gear'}
              disabled
              onClick={e => setActiveTab(e.target.getAttribute('value'))}
            >
              <MainText variant={'dark900'}>Gear</MainText>
              <div className="line" />
            </li>
            <li
              className={`TourDetail__included-nav__tabs-tab ${activeTab === 'details' ? 'active' : ''}`}
              value={'details'}
              disabled
              onClick={e => setActiveTab(e.target.getAttribute('value'))}
            >
              <MainText variant={'dark900'}>Details</MainText>
              <div className="line" />
            </li>
          </ul>
        </Element>

        {activeTab === 'bikes' && (
          <>
            <div className="TourDetail__included-bikes container">
              {bikes?.map(bike => (
                <BikeCard bike={bike} loading={bikesLoading} />
              ))}
            </div>

            {bikes?.length > 3 && (
              <ButtonM type="outlined">
                <ButtonMText variant={'jungle500'}>View all bikes</ButtonMText>
              </ButtonM>
            )}
          </>
        )}

        {activeTab === 'included' && (
          <>
            <div className="TourDetail__included-includes container">
              <div className="TourDetail__included-includes__items">
                {tour?.includes?.map(include => (
                  <div className="TourDetail__included-includes__items-item">
                    <img
                      className="TourDetail__included-includes__items-item__img"
                      src={include?.icon}
                    />
                    <MainText variant={'dark900'}>{include?.title}</MainText>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Element>

      <Element className="TourDetail__prices container" name="Date & Prices">
        <Element className="TourDetail__prices-filters" name="Prices Filters">
          <Headline3 variant={'dark900'}>Date & Prices</Headline3>
          <div className="TourDetail__prices-filters__inputs">
            <Element
              className="TourDetail__prices-filters__inputs-tabs"
              name="Date & Prices tabs"
            >
              <nav className="TourDetail__prices-filters__inputs-tabs__items">
                <li className="TourDetail__prices-filters__inputs-tabs__items-item active">
                  <ButtonMText variant={'dark720'}>Scheduled Dates</ButtonMText>
                </li>
                <li
                  className="TourDetail__prices-filters__inputs-tabs__items-item"
                  disabled
                >
                  <ButtonMText variant={'dark720'}>Private dates</ButtonMText>
                </li>
              </nav>
            </Element>
            <Element
              className="TourDetail__prices-filters__inputs-selector"
              name="Date & Prices Select"
            >
              <Formik
                initialValues={{
                  year: '',
                }}
              >
                <Field
                  name="year"
                  component={InputDrop}
                  className="TourDetail__prices-filters__inputs-selector__select"
                  backgroundColor="#FFFFFF"
                  options={[
                    { label: '2024', value: '2024' },
                    { label: '2025', value: '2025' },
                    { label: '2026', value: '2026' },
                    { label: '2027', value: '2027' },
                  ]}
                  placeholder="Select year"
                />
              </Formik>
            </Element>
          </div>
        </Element>
        <Element className="TourDetail__prices-table" name="Prices Table">
          {schedulers?.map(schedule => (
            <ScheduledPriceCard schedule={schedule} />
          ))}
        </Element>
      </Element>

      <Element className="TourDetail__accordeons container" name="Day to day">
        <h3 className="TourDetail__accordeons__title">Day to day</h3>

        {dayToDay.map((item, index) => (
          <Accordion className="TourDetail__accordeons__accordeon">
            <AccordionSummary
              expandIcon={<ArrowDown2 />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="TourDetail__accordeons__accordeon-header"
            >
              <Headline4 variant={'dark900'}>{index + 1}</Headline4>
              <Headline4
                variant={'dark900'}
                className="TourDetail__accordeons__accordeon__title"
              >
                {item.title}:
              </Headline4>
            </AccordionSummary>
            <AccordionDetails>
              <MainText
                variant={'dark720'}
                className="TourDetail__accordeons__accordeon__description"
              >
                {item.description}
              </MainText>
            </AccordionDetails>
          </Accordion>
        ))}
      </Element>

      <Element className="TourDetail__highlights container" name="Highlights">
        <div className="TourDetail__highlights__left">
          <Headline2
            variant={'dark900'}
            className="TourDetail__highlights__left__title"
          >
            Highlights
          </Headline2>

          <MainText variant={'dark720'}>
            <p dangerouslySetInnerHTML={{ __html: tour.highlights }}></p>
          </MainText>
        </div>
        <img src={Map} alt="" />
      </Element>

      <Element className="TourDetail__hotels container" name="Our Hotel">
        <div className="TourDetail__hotels-controlls">
          <Headline2 variant={'dark900'}>Our hotels</Headline2>
          {tour?.hotels?.length > 4 && (
            <div className="TourDetail__hotels-controlls__buttons">
              <div className="TourDetail__hotels-controlls__buttons-prev">
                <ArrowLeft2 color="#1D2026" />
              </div>
              <div className="TourDetail__hotels-controlls__buttons-next">
                <ArrowRight2 color="#1D2026" />
              </div>
            </div>
          )}
        </div>

        <Swiper
          spaceBetween={24}
          slidesPerView={4}
          loop={true}
          modules={[Navigation]}
          className="TourDetail__hotels-swiper"
          navigation={{
            prevEl: '.TourDetail__hotels-controlls__buttons-prev',
            nextEl: '.TourDetail__hotels-controlls__buttons-next',
          }}
        >
          {tour?.hotels?.map((hotel, index) => (
            <SwiperSlide
              key={index}
              className="TourDetail__hotels-swiper__slide"
            >
              <HotelsCard hotel={hotel} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="TourDetail__hotels-cards"></div>
      </Element>

      <Element
        className="TourDetail__reviews container"
        name="Reviews"
      ></Element>
    </div>
  );
};

export default TourDetail;
