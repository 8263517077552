import PageLayout from './shared/PageLayout/PageLayout';

import { useLocation, useRoutes } from 'react-router-dom';
import Login from './components/Login/Login';
import './styles/index.scss';
import routes from './routing/routes';
import Registration from './components/Registration/Registration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import { useEffect } from 'react';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Прокручивает страницу к началу
  }, [pathname]); // Этот эффект будет выполняться при каждом изменении пути

  return null;
};

function App() {
  const content = useRoutes(routes);
  return (
    <PageLayout>
      <ScrollToTop />
      <Login />
      <Registration />
      <ForgotPassword />
      {content}
    </PageLayout>
  );
}

export default App;
