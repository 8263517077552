// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurBlogCard {
  width: 282px;
  border-radius: 16px;
  background: #fff;
}
.OurBlogCard img {
  max-width: 282px;
  width: 100%;
  height: 200px;
}
.OurBlogCard__bottom {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.OurBlogCard__date {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #747b8a;
}
.OurBlogCard__title {
  font-family: Mulish;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1d2026;
}
.OurBlogCard__description {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4e5666;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/OurBlog/components/OurBlogCard/OurBlogCard.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;EACA,aAAA;AAEJ;AAAE;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AAEJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAIE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":[".OurBlogCard {\n  width: 282px;\n  border-radius: 16px;\n  background: #fff;\n  img {\n    max-width: 282px;\n    width: 100%;\n    height: 200px;\n  }\n  &__bottom {\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 16px;\n  }\n\n  &__date {\n    display: flex;\n    align-items: center;\n    column-gap: 5px;\n\n    font-family: Mulish;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #747b8a;\n  }\n\n  &__title {\n    font-family: Mulish;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    text-align: left;\n    color: #1d2026;\n  }\n\n  &__description {\n    font-family: Mulish;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #4e5666;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
