import React from 'react';
import './NewsCard.scss';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DetailsText, DetailsTextSetInnerHtml } from '../../Text/Body/Bodies';
import { Headline4 } from '../../Text/Headlines/Headlines';
import { ButtonM } from '../../Buttons/Buttons';
import { Link } from 'react-router-dom';

const NewsCard = ({ news }) => {
  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }
  return (
    <div className="NewsCard">
      <img src={news.icon} alt="NewsImage" />
      <div className="NewsCard__info">
        <div className="NewsCard__info-date">
          <CalendarMonthIcon sx={{ color: '#747B8A', fontSize: '20px' }} />
          <DetailsText variant={'dark540'}>
            {formatDate(news.created_at)}
          </DetailsText>
        </div>
        <Headline4 variant={'dark900'}>{news.title}</Headline4>
        <DetailsTextSetInnerHtml variant={'dark720'}>
          {news.text}
        </DetailsTextSetInnerHtml>
      </div>
      <Link to={`/switzerland/sdmaskjdnaskjdn`}>
        <ButtonM fullWidth={true} variant={'jungle500'} type={'outlined'}>
          Read
        </ButtonM>
      </Link>
    </div>
  );
};

export default NewsCard;
