import React from 'react';
import './DateToDate.scss';
import { CaptionsText, DetailsText, MainText } from '../Text/Body/Bodies';
import { Element } from 'react-scroll';

const DateToDate = ({ startDate, endDate }) => {
  // Функция для форматирования даты
  const formatDate = date => {
    const options = { month: 'short', day: 'numeric', weekday: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  // Вычисляем разницу в днях между датами
  const calculateDaysDifference = (start, end) => {
    const diffTime = Math.abs(new Date(end) - new Date(start));
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const daysDifference = calculateDaysDifference(startDate, endDate);

  return (
    <Element className="date-to-date">
      <div className="date-to-date__item">
        <DetailsText
          textAlign={'center'}
          variant={'dark720'}
          className="date-to-date__weekday"
        >
          {formattedStartDate.split(', ')[0]}
        </DetailsText>
        <MainText
          textAlign={'center'}
          variant={'dark900'}
          className="date-to-date__date"
        >
          {formattedStartDate.split(', ')[1]}
        </MainText>
      </div>
      <div className="date-to-date__middle">
        <CaptionsText variant={'dark540'} className="date-to-date__days">
          {daysDifference} days
        </CaptionsText>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="99"
          height="6"
          viewBox="0 0 99 6"
          fill="none"
        >
          <path
            d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM99 3L94 0.113249V5.88675L99 3ZM3 3.5H4V2.5H3V3.5ZM6 3.5H8V2.5H6V3.5ZM10 3.5H12V2.5H10V3.5ZM14 3.5H16V2.5H14V3.5ZM18 3.5H20V2.5H18V3.5ZM22 3.5H24V2.5H22V3.5ZM26 3.5H28V2.5H26V3.5ZM30 3.5H32V2.5H30V3.5ZM34 3.5H36V2.5H34V3.5ZM38 3.5H40V2.5H38V3.5ZM42 3.5H44V2.5H42V3.5ZM46 3.5H48V2.5H46V3.5ZM50 3.5H52V2.5H50V3.5ZM54 3.5H56V2.5H54V3.5ZM58 3.5H60V2.5H58V3.5ZM62 3.5H64V2.5H62V3.5ZM66 3.5H68V2.5H66V3.5ZM70 3.5H72V2.5H70V3.5ZM74 3.5H76V2.5H74V3.5ZM78 3.5H80V2.5H78V3.5ZM82 3.5H84V2.5H82V3.5ZM86 3.5H88V2.5H86V3.5ZM90 3.5H92V2.5H90V3.5ZM94 3.5H96V2.5H94V3.5Z"
            fill="#007C6E"
          />
        </svg>
        <MainText variant={'jungle500'} className="date-to-date__watch">
          Watch
        </MainText>
      </div>
      <div className="date-to-date__item">
        <DetailsText
          textAlign={'center'}
          variant={'dark720'}
          className="date-to-date__weekday"
        >
          {formattedEndDate.split(', ')[0]}
        </DetailsText>
        <MainText
          textAlign={'center'}
          variant={'dark900'}
          className="date-to-date__date"
        >
          {formattedEndDate.split(', ')[1]}
        </MainText>
      </div>
    </Element>
  );
};

export default DateToDate;
