import {
  Headline1,
  Headline2,
} from '../../components/Text/Headlines/Headlines';
import 'swiper/css';
import './Switzerland.scss';
import { Skeleton } from '@mui/material';
import { Navigation } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import { getBlogs } from '../../api/Blogs/Blogs.api';
import ToursCard from './components/ToursCard/ToursCard';
import { getAllTourTypes } from '../../api/Tours/Tours.api';
import NewsCard from '../../components/Card/NewsCard/NewsCard';
import { ImportantText } from '../../components/Text/Body/Bodies';
import { getCountryById } from '../../api/Countries/Countries.api';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Switzerland = () => {
  const [country, setCountry] = useState({});
  const [tours, setTours] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const getCountry = async () => {
    const response = await getCountryById(id);
    setCountry(response.data);
  };

  const getAllTours = async () => {
    const response = await getAllTourTypes({ country__id: id });
    setTours(response.results);
  };

  const getAllBlogs = async () => {
    const response = await getBlogs({ country__id: id });
    setBlogs(response.data.results);
  };

  const fetchData = async () => {
    await Promise.all([getCountry(), getAllTours(), getAllBlogs()]);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
    console.log(country);
  }, []);

  return (
    <div className="Switzerland">
      <div className="Switzerland__banner">
        {loading ? (
          <Skeleton width={'100%'} height={401}></Skeleton>
        ) : (
          <>
            <img src={country.icon} />
            <Headline1 variant={'white'}>{country.name}</Headline1>
          </>
        )}
      </div>
      <div className="container">
        <div className="Switzerland__about">
          <div className="Switzerland__about-text">
            {loading ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '32px', width: 430 }}
              ></Skeleton>
            ) : (
              <Headline2 variant={'dark900'}>About {country.name}</Headline2>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={588}
                height={156}
              ></Skeleton>
            ) : (
              <ImportantText variant={'dark720'}>
                {country?.description}
              </ImportantText>
            )}
          </div>
          {loading ? (
            <Skeleton variant="rounded" width={486} height={216}></Skeleton>
          ) : (
            <iframe
              width="486"
              height="216"
              src={country.url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
        <div className="Switzerland__tours">
          <Headline2 variant={'dark900'}>Tours</Headline2>
          <div className="line"></div>
          <div className="Switzerland__tours-cards">
            {loading ? (
              // Empty Cards
              <>
                <ToursCard loading={loading} />
                <ToursCard loading={loading} />
                <ToursCard loading={loading} />
                <ToursCard loading={loading} />
                <ToursCard loading={loading} />
                <ToursCard loading={loading} />
              </>
            ) : (
              tours.map((tour, index) => (
                <ToursCard idx={index} tour={tour} loading={loading} />
              ))
            )}
          </div>
        </div>
        <div className="Switzerland__blogs">
          <div className="Switzerland__blogs-controlls">
            <Headline2 variant={'black'}>Switzerland blog</Headline2>
            <div className="Switzerland__blogs-controlls__swiper">
              <div className="Switzerland__blogs-controlls__swiper-prev">
                <KeyboardArrowLeftIcon />
              </div>
              <div className="Switzerland__blogs-controlls__swiper-next">
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </div>
          <Swiper
            spaceBetween={24}
            slidesPerView={4}
            loop={true}
            modules={[Navigation]}
            navigation={{
              prevEl: '.Switzerland__blogs-controlls__swiper-prev',
              nextEl: '.Switzerland__blogs-controlls__swiper-next',
            }}
          >
            {blogs?.map((blog, index) => (
              <SwiperSlide key={index}>
                <NewsCard news={blog} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {/* <div className="Switzerland__gallery">
        <div className="container">
          <div className="Switzerland__gallery-controlls">
            <Headline2 variant={'dark900'}>Gallery</Headline2>
            <div className="Switzerland__gallery-controlls__swiperControlls">
              <div className="Switzerland__gallery-controlls__swiperControlls-prev">
                <KeyboardArrowLeftIcon />
              </div>
              <div className="Switzerland__gallery-controlls__swiperControlls-next">
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </div>
        </div>
        <Swiper
          slidesPerView={6}
          centeredSlides={true}
          loop={true}
          modules={[Navigation]}
          className="Switzerland__gallery-swiper"
          navigation={{
            prevEl: '.Switzerland__gallery-controlls__swiperControlls-prev',
            nextEl: '.Switzerland__gallery-controlls__swiperControlls-next',
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={swiper => console.log(swiper)}
        >
          {SwitzerlandGallery.map((galleryImage, idx) => (
            <SwiperSlide key={idx}>
              <img src={galleryImage.image} alt={`Image ${idx}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </div>
  );
};

export default Switzerland;
