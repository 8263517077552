import { backend } from '../index';

export const createReservation = async data => {
  try {
    const response = await backend.post(`/reserves/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('LCK'))}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error create reservation:', error);
    throw error;
  }
};
