import React from 'react';

import './AboutUs.scss';
import {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
} from '../../components/Text/Headlines/Headlines';

import PlayCircle from '../../assets/icons/play-circle.svg';
import HowItWorksCard from './components/HowItWorksCard/HowItWorksCard';
import AdviceIcon from '../../assets/images/Main/Benefits/Advice.svg';
import GreenCall from '../../assets/icons/greenCall.svg';
import GreenMail from '../../assets/icons/greenSms.svg';

import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import { ButtonL } from '../../components/Buttons/Buttons';
import WhatSayAboutUsCard from './components/WhatSayAboutUsCard/WhatSayAboutUsCard';

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <div className="AboutUs__header">
        <Headline1 variant={'white'}>About us</Headline1>
      </div>

      <div className="container">
        <div className="AboutUs__wrapper">
          <div className="AboutUs__whoWeAre">
            <div className="AboutUs__whoWeAre__text">
              <Headline2>Who we are</Headline2>

              <p className="AboutUs__whoWeAre__text__description">
                We provide cycling vacations of a lifetime in the world’s most
                beautiful destinations. From luxury experiences to easygoing
                self-guided adventures, we have a trip for every rider out
                there. Our trips include the use of award-winning Trek bicycles,
                including e-bikes, so you can ride the best routes with the best
                gear. Our team has been crafting unforgettable cycling vacations
                since 2002, so you can leave the planning to us and focus on
                nothing but having fun! Let’s go
              </p>
            </div>

            <div className="AboutUs__whoWeAre__player">
              <img src={PlayCircle} alt="" />
            </div>
          </div>

          <div className="AboutUs__howItWorks">
            <Headline2>How it works</Headline2>
            <div className="AboutUs__howItWorks__cards">
              <HowItWorksCard
                icon={AdviceIcon}
                title={'Expertise and Advice'}
                description={
                  'We can give personalized advice based on our knowledge of destinations, locations and activities'
                }
              />
              <HowItWorksCard
                icon={AdviceIcon}
                title={'Expertise and Advice'}
                description={
                  'We can give personalized advice based on our knowledge of destinations, locations and activities'
                }
              />{' '}
              <HowItWorksCard
                icon={AdviceIcon}
                title={'Expertise and Advice'}
                description={
                  'We can give personalized advice based on our knowledge of destinations, locations and activities'
                }
              />{' '}
              <HowItWorksCard
                icon={AdviceIcon}
                title={'Expertise and Advice'}
                description={
                  'We can give personalized advice based on our knowledge of destinations, locations and activities'
                }
              />
            </div>
          </div>

          <div className="AboutUs__gallery">
            <Headline2>Gallery</Headline2>
            <div className="AboutUs__gallery__images">
              <div className="AboutUs__gallery__leftSide">
                <img
                  src="https://s3-alpha-sig.figma.com/img/e386/4924/f6917fdc73dcd10c163a7794340185c7?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nd98WgMWLNJOz9dp6BEDIjkNS6-vAvho903Vv0OleeXn4CV7sdYhytJshLYxb8KlcJgCeXgiL6F7cGhN2F4rxfFi5rlC2ayfZsZ0eIOsiMH6adqExSxbjrqV~feOcdvpWuhl~SjeZ~a1a4lwu48rFCt5Nplz5x3QSQrXBSnCSBoJQ7kYhGJnlyHnK2o37GDH~GsIHsBuJyVKD49T8SVWNYmxXh1HoaCyUlnhum~yD56YcR3-CnReb8AWCHY3hYx0H7KwZXhvD09LbAVs0jrplD5Qd-BJLrum4nPb8EIVIkaziVmQYGZfgwm4oXzZ1aek45yFhZzs6ZuZdFNGuR40oQ__"
                  alt=""
                  className="AboutUs__gallery__leftSide__big"
                />
                <img
                  src="https://s3-alpha-sig.figma.com/img/5536/7b69/3ce3baad5345402f9cab6229d5255906?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FYR~gi3bCtCO0v~evDB82eDpze6K~aEa8g~v4jVSkQ4~AeyLQwhmGbQpgs-Ba~Mc70wAcEzixFsPyAdTV-Z1iBFj9nVmDUnxBW1fAKOPbC~Gcf~522PCCWE~ziHf0VMVrwlpaaNO8zPx7sUbt8x~LyOxqpJyv~PesYbySWBopQsI8qS~RKEpM9Mhg3XxWpj9MfxkFmaDNNN~EkdXR4bAuqf7ZoEq9Ul8aEAWHI7iDaaxDCfIOXSJIXN7BmwMrx6QRvmve~UitoerSBu4y0cNthSSl3gayewX2XRCFIomHhDQ7UtGHsqPc86workkLlGjDrPno-u5R7pS3Ga1c1TwsQ__"
                  alt=""
                  className="AboutUs__gallery__leftSide__small"
                />
                <img
                  src="https://s3-alpha-sig.figma.com/img/4c33/02d8/6113aa0b4176791a85fa242e8270cfc3?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=f~4T25PDDXgEfZinzsG-pg~FiGZYuO2dt--qZ3gr2xhK7gTMQrvq4y32dH97INaFSkCB4UlNJgdlQZRk0MwsMeOyMW6hHKQc8CuBhJW6XPFuDyNe4dQxFiiOu~ud6zidyYmBXiRODlxrY702lw7NJpmrNA5EQLkWjBgAFXBozYK1ipGKc97xS72sTxUtOPvxu6BKgNr~~qcQZvZXwHWMvAFoeBbBsio~qv3Yalpk1uTf5eLOa9kre3WuPfIQEcjLduhx5KG5dhUBISQ4uDZoaB9H7tW63RiWOxJrAvc0XJa06cA~d8FvKow7xwU0ojuOrONIhm6JnBG7g7RfMU7W1A__"
                  alt=""
                  className="AboutUs__gallery__leftSide__small"
                />
              </div>
              <div className="AboutUs__gallery__rightSide">
                <img
                  src="https://s3-alpha-sig.figma.com/img/00f1/b33f/ea591bcf26233a0261eec859a2742d3d?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GZiLckTr4qvp1BxdWuft5xzrEASm6nVJdvBCdl7fq1FCXfjIYFaksjtKu2sZdPfKjrgGzqx3S1OnSO~hIcbKOtCbR2O8nGO~t2x3v6EjaP~KAEd2Nhs-MmJM6gIdiUYyfCt-2b4QQS4ZpEbpjcLVcZPnfAESvooFN2IvgMNreJiVxwKv17pzcHu3Ng14a0pv5o8RtPUQCxsvN7jNjClEXBAvGS~LvqSaHEkcxbt30rnpGyuFJXuo~T-HeF46BgwyMRzx-Y-80N1IKK04LeymAzg9xLn8E3g7OaLkv6JD8DXG346ln~NlGaMsDez38JPqLRkWekx0FyqUQLuLrivu7w__"
                  alt=""
                  className="AboutUs__gallery__rightSide__image"
                />
                <img
                  src="https://s3-alpha-sig.figma.com/img/6f51/6794/8a39e291592328467299a8653907869e?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Qi66~9u3UMQa~7s3ziyWnLl0XBk46mg4b4znc2PGngAk4zUfWT4V6WAl-GoShyb7SKtwENma0~1vwrkSALKXAqX0Mp6nptUFZg45CkmSmUmVpBUT~N4lg0UmmPXgD5dflMe4otmf9rT-3TJWTvEdf1vghG7RmaSTQ1619UQrlzGjpq-NJfbkWspuIUhuIYe5sFTH9S5GYQrTQG5IS0CNcVQLoCfZW5-J6LwDR2xjj2UNuuMa805K~xfJyNOG41EHLVKTyw-hJHPDWgtZ977DHmXjwVK1byHoDKbETW30eJ1Lu8huJifjy9kYiHt2imMeECpTbNcIgdzM1pkf4ER9Jg__"
                  alt=""
                  className="AboutUs__gallery__rightSide__image"
                />
              </div>
            </div>
          </div>

          <div className="AboutUs__contact">
            <div className="AboutUs__contact__leftSide">
              <Headline2>Contact us</Headline2>

              <p className="AboutUs__contact__leftSide__description">
                Any questions? We would be happy to help you!
              </p>

              <div className="AboutUs__contact__leftSide__phone">
                <a
                  href="callto:+77015338070"
                  className="AboutUs__contact__leftSide__phone__number"
                >
                  <img src={GreenCall} alt="" />+ 7 701 533 8070
                </a>
              </div>
              <a
                href="mailto:Nt.voyage@gmail.com"
                className="AboutUs__contact__leftSide__mail"
              >
                <img src={GreenMail} alt="" />
                Nt.voyage@gmail.com
              </a>

              <div className="AboutUs__contact__leftSide__weAreSocial">
                <Headline4>We are in social:</Headline4>

                <div className="AboutUs__contact__leftSide__weAreSocial__socials">
                  <img
                    src={FacebookIcon}
                    alt=""
                    className="AboutUs__contact__leftSide__weAreSocial__socials__social"
                  />
                  <img
                    src={InstagramIcon}
                    alt=""
                    className="AboutUs__contact__leftSide__weAreSocial__socials__social"
                  />
                  <img
                    src={YoutubeIcon}
                    alt=""
                    className="AboutUs__contact__leftSide__weAreSocial__socials__social"
                  />
                </div>
              </div>
            </div>
            <div className="AboutUs__contact__rightSide">
              <div className="AboutUs__contact__rightSide__fields">
                <StyledTextField
                  className={'AboutUs__contact__rightSide__field'}
                  placeholder={'First name'}
                />{' '}
                <StyledTextField
                  className={'AboutUs__contact__rightSide__field'}
                  placeholder={'Last name'}
                />{' '}
                <StyledTextField
                  className={'AboutUs__contact__rightSide__field'}
                  placeholder={'Email'}
                />{' '}
                <StyledTextField
                  className={'AboutUs__contact__rightSide__field'}
                  placeholder={'Phone number'}
                />
              </div>

              <div className="AboutUs__contact__rightSide__message">
                <Headline4>Message</Headline4>

                <StyledTextField
                  fullWidth
                  className={'AboutUs__contact__rightSide__message__field'}
                  placeholder={'Type your message here...'}
                />
              </div>
              <ButtonL variant={'white'}>Send message</ButtonL>
            </div>
          </div>

          <div className="AboutUs__whaySayAboutUs">
            <Headline2>What say about us</Headline2>

            <div className="AboutUs__whaySayAboutUs__cards">
              <WhatSayAboutUsCard
                customer={'Christian Northgate'}
                comment={
                  'Absolutely loved the cycling tour! The routes were well-designed, taking us through scenic landscapes and charming villages.'
                }
                rating={'4.8'}
                date={'10 may 2023'}
              />
              <WhatSayAboutUsCard
                customer={'Christian Northgate'}
                comment={
                  'Absolutely loved the cycling tour! The routes were well-designed, taking us through scenic landscapes and charming villages.'
                }
                rating={'4.8'}
                date={'10 may 2023'}
              />{' '}
              <WhatSayAboutUsCard
                customer={'Christian Northgate'}
                comment={
                  'Absolutely loved the cycling tour! The routes were well-designed, taking us through scenic landscapes and charming villages.'
                }
                rating={'4.8'}
                date={'10 may 2023'}
              />{' '}
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
