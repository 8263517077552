// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhatSayAboutUsCard {
  background: #ffffff;
  padding: 16px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.WhatSayAboutUsCard__date {
  display: flex;
  align-items: center;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4e5666;
  column-gap: 8px;
}
.WhatSayAboutUsCard__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.WhatSayAboutUsCard__content__comment {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #4e5666;
}
.WhatSayAboutUsCard__content__rating {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1d2026;
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/components/WhatSayAboutUsCard/WhatSayAboutUsCard.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAGI;EAEE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFN;AAKI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAJN","sourcesContent":[".WhatSayAboutUsCard {\n  background: #ffffff;\n  padding: 16px 24px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n\n  &__date {\n    display: flex;\n    align-items: center;\n    //styleName: Web/Body/Details;\n    font-family: Mulish;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #4e5666;\n    column-gap: 8px;\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    &__comment {\n      //styleName: Web/Body/Main text;\n      font-family: Mulish;\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px;\n      text-align: left;\n      color: #4e5666;\n    }\n\n    &__rating {\n      display: flex;\n      align-items: center;\n      column-gap: 8px;\n      //styleName: Web/Body/Main text;\n      font-family: Mulish;\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px;\n      text-align: left;\n      color: #1d2026;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
