import React from 'react';

import './OurBlogCard.scss';
import OutlinedButton from '../../../../../../components/Buttons/OutlinedButton/OutlinedButton';
import { useNavigate } from 'react-router-dom';

const OurBlogCard = ({ image, date, title, description, to }) => {
  const navigate = useNavigate();

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }

  const handleNavigate = () => {
    navigate(to);
  };
  return (
    <div className="OurBlogCard" onClick={() => handleNavigate()}>
      <img src={image} alt="" className="OurBlogCard__img" />

      <div className="OurBlogCard__bottom">
        <p className="OurBlogCard__date">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66699 1.6665V4.1665"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.333 1.6665V4.1665"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.91699 7.5752H17.0837"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z"
              stroke="#747B8A"
              stroke-width="1.2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0791 11.4167H13.0866"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0791 13.9167H13.0866"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99607 11.4167H10.0036"
              stroke="#747B8A"
              stroke-width="2"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99607 13.9167H10.0036"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.91209 11.4167H6.91957"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.91209 13.9167H6.91957"
              stroke="#747B8A"
              stroke-width="1.8"
              stroke-linecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {formatDate(date)}
        </p>

        <h3 className="OurBlogCard__title">{title}</h3>

        <p
          className="OurBlogCard__description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></p>

        <OutlinedButton text={'Read'} fullWidth />
      </div>
    </div>
  );
};

export default OurBlogCard;
