import React from 'react';
import './Login.scss';
import CloseIcon from '../../assets/icons/CloseIcon';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import SmsIcon from '../../assets/icons/SmsIcon';
import EyeIcon from '../../assets/icons/EyeIcon';
import GoogleIcon from '../../assets/icons/GoogleIcon';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ForgotPasswordModal,
  loginModal,
  RegistrationModal,
} from '../../store/modals/modals.store';

import Modal from '@mui/material/Modal';
import { signIn } from '../../api/SignIn/SignIn.api';
import { userState } from '../../store/users/users.store';

const Login = () => {
  const [loginModalState, setLoginModalState] = useRecoilState(loginModal);
  const setUser = useSetRecoilState(userState);

  const setForgotPasswordState = useSetRecoilState(ForgotPasswordModal);
  const setRegistrationModalState = useSetRecoilState(RegistrationModal);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async values => {
      const response = await signIn(values);

      localStorage.setItem('LCK', response.access);
      localStorage.setItem('refreshToken', response.refresh);
      setUser(response.user);

      setLoginModalState(false);
    },
  });

  if (!loginModalState) {
    return;
  }

  return (
    <Modal
      open={loginModalState}
      onClose={() => setLoginModalState(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="Login">
        <div className="Login__block">
          <div className="Login__block-title">
            <h2>Sign into your account</h2>

            <button
              className="Login__block-close"
              onClick={() => setLoginModalState(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="Login__form">
            <div className="Login__form-input">
              <label htmlFor="password">Email</label>
              <div className="Login__form-input-field">
                <TextField
                  fullWidth
                  id="email"
                  margin="none"
                  variant="standard"
                  focused
                  name="username"
                  label="Enter your email"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
                <SmsIcon />
              </div>
            </div>
            <div className="Login__form-input">
              <label htmlFor="password">Password</label>
              <div className="Login__form-input-field">
                <TextField
                  fullWidth
                  focused
                  margin="none"
                  variant="standard"
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <EyeIcon />
              </div>
            </div>
            <p
              className="Login__form-forgot"
              onClick={() => {
                setLoginModalState(false);
                setForgotPasswordState(true);
              }}
            >
              Forgot password?
            </p>

            <div className="Login__form-buttons">
              <button type="submit" className="Login__form-buttons-button">
                Sign In
              </button>
              <p className="Login__form-buttons-signUp">
                Dont have an account yet?
                <span
                  onClick={() => {
                    setLoginModalState(false);
                    setRegistrationModalState(true);
                  }}
                >
                  Sign Up
                </span>
              </p>
            </div>
          </form>
          {/* <div className="Login__or">
            <div className="line"></div>
            <p>Or with</p>
            <div className="line"></div>
          </div>
          <button className="Login__google">
            <GoogleIcon></GoogleIcon>
            Sign in with Google
          </button> */}
        </div>
      </div>
    </Modal>
  );
};

export default Login;
